import { React, useState, useEffect } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import surveypage from "../../assets/images/SurveyLanding.png";
import { getrequest, postrequest } from "../../services/global.service";
import {
  CalendarPicker,
  InputNumber,
  Select,
  Tooltip,
  TextArea,
  Loading,
  Button,
  Notification,
  toaster,
  Tab,
  Tabs,
  Panel,
  Radio,
  Modal,
  Badge
} from "@appkit4/react-components";

const SurveyForm = () => {
  const [questinnaireJSON, setquestinnaireJSON] = useState([]);
  const [surveyVersion, setSurveyVersion] = useState("");
  const [data, setData] = useState({});
  const [surveyResponseId, setSurveyResponseId] = useState("");
  const [resSurveyName, setResSurveyName] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startSurvey, setStartSurvey] = useState(false);
  const [activeIndex, setactiveIndex] = useState(0);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();
  const { surveyId } = useParams();

  Cookies.set("path", location.pathname, {
    sameSite: "strict",
    secure: true,
  });

  var saveSurveyResponseURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/SaveSurveyResponse`;
  var getSurveyDetailsURL = `${process.env.REACT_APP_API_BASE_URL}/api/Survey/GetSurvey`;
  useEffect(() => {
    setIsLoading(true);
    getSurveyDetails();
  }, []);
  const getSurveyDetails = async () => {
    await getrequest(getSurveyDetailsURL, {
      params: {
        surveyId: surveyId,
        surveyUserEmail: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail,
      },
    })
      .then((response) => {
        
        if (response.data.result) {
          let data = response.data.data;
          let arr = JSON.parse(data);
          setIsSubmitted(arr.isSubmit);
          setIsActive(arr.isActive);
          setIsExpired(arr.isExpired);
          setSurveyDescription(arr.surveyDescription.split("\n"));
          setquestinnaireJSON(
            JSON.parse(arr.surveyQuestionnaire).components[0].components
          );

          setSurveyVersion(arr.version);
          setResSurveyName(arr.surveyName);
          if (arr.surveyResponse && arr.surveyResponse !== "") {
            setData(JSON.parse(arr.surveyResponse));
          }
          if (arr.surveyResponseId != null)
            setSurveyResponseId(arr.surveyResponseId);
          setIsLoading(false);
        } 
        
        else {
          console.log("level 1");
          console.log(response.data.result);
          console.log(response.data.data);
          console.log(JSON.parse(data));
          console.log(JSON.parse(response.data));
          console.log(response.data);
          navigate("/unauthorised");
        }
      })
      .catch((error) => {
        console.log("level 2");
        navigate("/service-problem");
      });
  };

  const evalCustomConditional = (cc, data) => {
    if (!cc) return false;

    const eqSymbolLocation = cc.indexOf("=") + 1;
    let expr = cc.substring(eqSymbolLocation).trim();
    expr = expr?.replace(/\.includes/g, "?.includes");
    expr = expr?.replace(/\)data/g, ") && data");

    return executeExpression(expr, data);
  };

  const executeExpression = (expr, data) => {
    try {
      return !!eval(expr);
    } catch (error) {
      return false;
    }
  };

  const getComponentRequiredComponents = (c) => {
    if (!c) return null;

    if (c.type === "datagrid") return getDatagridRequiredComponents(c);

    if (
      c.validate?.required === true &&
      (!c.customConditional || evalCustomConditional(c.customConditional))
    ) {
      return c;
    }

    return null;
  };

  const getDatagridRequiredComponents = (dtGrd) => {
    if (!dtGrd || dtGrd.type !== "datagrid") return null;

    const rows = (dtGrd.defaultValue[0] || []).map((h) =>
      h?.replace(/\s/g, "")
    );
    const tbl = dtGrd.components[0];
    let headerArr = tbl.rows[0];

    let out = [];
    for (let cell of headerArr) {
      let header = cell.components[0];
      if (header.validate.required) {
        out = out.concat(
          rows.map((rH) => ({
            key: `${header.key}_${rH}`,
          }))
        );
      }
    }
    return out;
  };

  const validateForm = () => {
    const requiredSections = questinnaireJSON.map((q) =>
      q.components.map((d) => getComponentRequiredComponents(d))
    );

    const requiredKeys = requiredSections
      .flat(2)
      .filter((v) => v)
      .map((t) => t.key);

    const completedComponents = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== undefined)
    );
    const completedKeys = Object.keys(completedComponents);

    const result = requiredKeys.filter(
      (rK) => !completedKeys.some((cK) => cK === rK)
    );

    setErrors(result);
    if (result.length) {
      return true;
    }
  };

  const submitForm = (type) => {
    if (type === "submit") {
      setSubmitLoading(true);
      if (validateForm()) {
        setSubmitLoading(false);
        setShowSubmitModal(false);
        return false;
      }
    }
    let payloadData = {
      surveyId: surveyId,
      surveyResponseId: surveyResponseId,
      surveyName: resSurveyName,
      surveyUserEmail: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail,
      surveyResponse: JSON.stringify(data),
      version: surveyVersion,
      isSubmit: type === "save" ? false : true,
    };
    console.log("payLoad Data QQQQQQQQQQQQQQQQQQ");
    console.log(payloadData);
    console.log("payLoad Data QQQQQQQQQQQQQQQQQQ");

    postrequest(saveSurveyResponseURL, payloadData)
      .then((response) => {
        if (response.data.result) {
          if (type === "save") {
            displayAlert(type);
          } else {
            navigate("/form-submitted");
          }
        }
      })
      .catch((error) => {
        console.log("level 3");

        navigate("/service-problem");
      });
  };

  const displayAlert = () => {
    return toaster.notify(
      <Notification
        title="Alert"
        message={"Answers saved successfully"}
        status=""
        closeable
      />,
      { position: "topCenter", duration: 5000 }
    );
  };

  const handleChange = (value, e) => {
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleNumberChange = (value, _formattedValue, e) => {
    setData({
      ...data,
      [e.target.name]: value
        .toString()
        .split(".")
        .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
        .join("."),
    });
  };

  const allTooltip = (text, customClass) =>
    (text !== "" || text === undefined) && (
      <Tooltip
        trigger="hover"
        position="top-left"
        distance={4}
        appendAfterTarget={true}
        content={text}
      >
        <button
          data-tooltip="true"
          tabIndex={0}
          aria-label="tooltip"
          className={`Appkit4-icon icon-information-outline ap-field-icon-btn mb-md-3 ${customClass}`}
          aria-describedby="field-tooltip"
        ></button>
      </Tooltip>
    );

  const buildTable = (colums, data) => {
    return (
      <table className="survey-table">
        <tr>
          <th></th>
          {data.map((d) => (
            <th>{d.components[0].label}</th>
          ))}
        </tr>
        {colums?.map((c) => (
          <tr>
            <td>{c}</td>
            {data.map((d) => (
              <td>{getFields(d.components[0], true, c)}</td>
            ))}
          </tr>
        ))}
      </table>
    );
  };

  const onTabChange = (i) => {
    setactiveIndex(i);
  };

  function isISODateString(str) {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/.test(str);
}

  const getFields = (question, table, c) => {
    if (
      !question.customConditional ||
      eval(
        question.customConditional
          ?.substring(question.customConditional?.indexOf("=") + 1)
          .trim()
          ?.replace(/\.includes/g, "?.includes")
          ?.replace(/\)data/g, ") && data")
      )
    ) {
      if (question.type === "textfield") {
        return (
          <>
            <TextArea
              title={!table && question.label}
              defaultValue={question.defaultValue}
              required={question.validate.required}
              maxLength={question.attributes.maxlength}
              name={
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              }
              onChange={handleChange}
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
            {errors.includes(
              table ? `${question.key}_${c?.replace(/\s/g, "")}` : question.key
            ) && (
              <div
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a value
              </div>
            )}
          </>
        );
      } else if (question.type === "select") {
        return (
          <>
            <Select
              data={question.data.values}
              required={question.validate.required}
              showSelectAll={true}
              multiple={question.multiple}
              placeholder={!table && question.label}
              name={question.key}
              onSelect={(value) =>
                setData((prevState) => ({
                  ...prevState,
                  [table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key]: value,
                }))
              }
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              error={errors.includes(
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              )}
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
            {errors.includes(
              table ? `${question.key}_${c?.replace(/\s/g, "")}` : question.key
            ) && (
              <div
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please select an option
              </div>
            )}
          </>
        );
      } else if (question.type === "datetime") {
        return (
          <>
            <CalendarPicker
              fieldTitle={!table && question.label}
              defaultValue={question.defaultValue}
              required={question.validate.required}
              fieldWidth="100%"
              format={"DD/MM/YYYY"}
              placeholder="DD/MM/YYYY"
              editable={false}
              disabledDays={[].concat(
                question.datePicker.disableWeekends && [5, 6],
                question.datePicker.disableWeekdays && [0, 1, 2, 3, 4]
              )}
              onChange={(date) =>
                setData((prevState) => ({
                  ...prevState,
                  [table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key]: date,
                }))
              }
value={
  data
    ? (table
        ? typeof data[`${question.key}_${c?.replace(/\s/g, "")}`] === 'string'
          ? isISODateString(data[`${question.key}_${c?.replace(/\s/g, "")}`])
            ? new Date(data[`${question.key}_${c?.replace(/\s/g, "")}`])
            : JSON.parse(data[`${question.key}_${c?.replace(/\s/g, "")}`])
          : data[`${question.key}_${c?.replace(/\s/g, "")}`]
        : typeof data[question.key] === 'string'
          ? isISODateString(data[question.key])
            ? new Date(data[question.key])
            : JSON.parse(data[question.key])
          : data[question.key]
      )
    : undefined
}

              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              useCustomValidation
              error={errors.includes(
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              )}
              customErrorNode={
                errors.includes(
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ) && (
                  <div
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a date
                  </div>
                )
              }
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
            {errors.includes(
              table ? `${question.key}_${c?.replace(/\s/g, "")}` : question.key
            ) && (
              <div
                aria-live="polite"
                className="ap-field-email-validation-error"
              >
                Please enter a date
              </div>
            )}
          </>
        );
      } else if (question.type === "number") {
        return (
          <>
            <InputNumber
              title={!table && question.label}
              defaultValue={question.defaultValue}
              required={question.validate.required}
              name={
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              }
              onChange={handleNumberChange}
              value={
                data[
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ]
              }
              className={
                question.tooltip === "" || !question.tooltip
                  ? `mb-md-3 ${question.customClass}`
                  : question.customClass
              }
              error={errors.includes(
                table
                  ? `${question.key}_${c?.replace(/\s/g, "")}`
                  : question.key
              )}
              errorNode={
                errors.includes(
                  table
                    ? `${question.key}_${c?.replace(/\s/g, "")}`
                    : question.key
                ) && (
                  <div
                    aria-live="polite"
                    className="ap-field-email-validation-error"
                  >
                    Please enter a number
                  </div>
                )
              }
              onInput={(e) =>
                (e.target.value = e.target.value.slice(
                  0,
                  question.attributes.maxlength
                ))
              }
              increaseIconClassName="inputNumberArrow"
              decreaseIconClassName="inputNumberArrow"
            />
            {question.tooltip &&
              allTooltip(question.tooltip, question.customClass)}
          </>
        );
      } else if (question.type === "memo") {
        return <p>{question.label?.replace(/^[0-9]+/g, "")}</p>;
      } else if (question.type === "datagrid") {
        return (
          <>
            <p>
              {question.label}

              {question.tooltip !== "" && (
                <Tooltip
                  trigger="hover"
                  position="top-left"
                  distance={4}
                  appendAfterTarget={true}
                  content={question.tooltip}
                >
                  <button
                    data-tooltip="true"
                    tabIndex={0}
                    aria-label="tooltip"
                    className="Appkit4-icon icon-information-outline ap-field-icon-btn"
                    aria-describedby="field-tooltip"
                  ></button>
                </Tooltip>
              )}
            </p>
            {buildTable(
              question.defaultValue[0],
              question.components[0].rows[0]
            )}
          </>
        );
      } else {
        return <p>There is a problem displaying this question</p>;
      }
    }
  };

  console.log(data);

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  return (
    <div className="ap-container">
      {startSurvey ? (
        <>
          <Button
            onClick={() => {
              setStartSurvey(false);
            }}
            kind="text"
            className="back-button"
          >
            Back
          </Button>

          <Panel title={resSurveyName && resSurveyName}>
            {!isSubmitted && isActive && !isExpired ? (
              <>
                <Tabs
                  type="underline"
                  activeIndex={activeIndex}
                  onTabChange={onTabChange}
                  className="mt-md-3"
                  responsive
                >
                  {questinnaireJSON.map((component) => (
                    <Tab label={component.label} value={component.value}>
                      {component.components.map((question) =>
                        getFields(question)
                      )}
                    </Tab>
                  ))}
                </Tabs>

                <div className="row">
                  <div className="col-1">
                    <Button
                      kind="text"
                      icon="icon-left-chevron-outline"
                      onClick={() => setactiveIndex(activeIndex - 1)}
                      disabled={activeIndex === 0}
                    >
                      Previous
                    </Button>
                  </div>
                  <div className="col-5">
                    <Button
                      kind="text"
                      icon="icon-right-chevron-outline"
                      onClick={() => setactiveIndex(activeIndex + 1)}
                      disabled={activeIndex === questinnaireJSON.length - 1}
                    >
                      Next
                    </Button>
                  </div>

                  <div className="col-5">
                    <Button
                      kind="secondary"
                      icon="icon-save-outline"
                      className="right"
                      onClick={() => submitForm("save")}
                    >
                      Save as draft
                    </Button>
                  </div>
                  <div className="col-1">
                    <Button
                      kind="primary"
                      icon="icon-circle-checkmark-outline"
                      className="right"
                      onClick={() => setShowSubmitModal(true)}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            ) : isSubmitted ? (
              <p>This form has already been submitted</p>
            ) : !isActive || isExpired ? (
              <p>This form is no longer available</p>
            ) : (
              <p>Something went wrong</p>
            )}
          </Panel>
        </>
      ) : (
        <Panel title={resSurveyName && resSurveyName}>
          <div className="row">
            <div className="col-6">
              <div>
                {surveyDescription &&
                  surveyDescription.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
              </div>

              <p>
                Before getting started please ensure you have read our{" "}
                <Link
                  to="/privacy-statement"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ap-link"
                >
                  Privacy Statement
                </Link>
                ,{" "}
                <Link
                  to="/cookies-statement"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ap-link"
                >
                  Cookies Statement
                </Link>{" "}
                and{" "}
                <Link
                  to="/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ap-link"
                >
                  Terms of Use
                </Link>
                .
              </p>

              <p className="mb-md-3">
                <Radio
                  checked={acceptPrivacy}
                  onClick={() => setAcceptPrivacy(true)}
                >
                  I have read and accepted the aforementioned Privacy Statement,
                  Cookies Statement and Terms of Use.
                </Radio>
              </p>

              <p>
                Please note that responses to all these questions may not be
                required, as this questionnaire is logic based and depends on
                the options that you select as you work through the
                questionnaire. Your questionnaire can be saved for you to return
                later until it is submitted.
              </p>

              <Button
                kind="primary"
                disabled={!acceptPrivacy}
                icon="icon-arrow-right-outline"
                onClick={() => setStartSurvey(true)}
              >
                Begin Survey
              </Button>
            </div>

            <div className="col-6">
              <img src={surveypage} alt="survey page" />
            </div>
          </div>
        </Panel>
      )}
      <Modal
          visible={showSubmitModal}
          title="Support"
          onCancel={() => {
            setShowSubmitModal(false);
          }}
          onS
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={<Badge type="warning" value="Warning"></Badge>}
          footer={
            <Button
              onClick={() => submitForm("submit")}
              loading={submitLoading}
              kind="primary"
              icon="icon-circle-checkmark-outline"
            >
              Submit
            </Button>
          }
          bodyStyle={{ minHeight: "92px" }}
        >
            <p>
              Are you sure to want to submit? Once you submit, you can not go back.
            </p>
        </Modal>
    </div>
  );
}

export default SurveyForm;
